var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.pageBreadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c(
            "v-card-text",
            [
              _vm.loading ? _c("psi-content-loader") : _vm._e(),
              !_vm.currentInstructorSelected
                ? _c(
                    "div",
                    { attrs: { id: "selectors" } },
                    [
                      _vm.programsFilled
                        ? _c("program-select", {
                            attrs: { programs: _vm.programs },
                            on: { "program-select": _vm.programSelect }
                          })
                        : _vm._e(),
                      _vm.currentProgramSelected
                        ? _c("instructor-select", {
                            key: _vm.$_.get(
                              _vm.currentProgram,
                              "program.id",
                              0
                            ),
                            attrs: {
                              program: _vm.currentProgram,
                              sessions: _vm.currentProgram.sessions,
                              selectedSession: _vm.selectedSession
                            },
                            on: {
                              "update:selectedSession": function($event) {
                                _vm.selectedSession = $event
                              },
                              "update:selected-session": function($event) {
                                _vm.selectedSession = $event
                              },
                              "instructor-select": _vm.instructorSelect
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentInstructorSelected
                ? _c("program-instructor", {
                    attrs: {
                      session: _vm.selectedSession,
                      instructor: _vm.currentInstructor,
                      program: _vm.currentProgram
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }