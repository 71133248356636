<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="pageBreadcrumbs"
        ></psi-page-title>
        <!-- Content always starts with a v-card  -->
        <v-card class="mt-4">
            <v-card-text>
                <psi-content-loader v-if="loading"></psi-content-loader>
                <div id="selectors" v-if="!currentInstructorSelected">
                    <program-select
                        v-if="programsFilled"
                        :programs="programs"
                        @program-select="programSelect"
                    ></program-select>
                    <instructor-select
                        :key="$_.get(currentProgram, 'program.id', 0)"
                        v-if="currentProgramSelected"
                        :program="currentProgram"
                        :sessions="currentProgram.sessions"
                        :selectedSession.sync="selectedSession"
                        @instructor-select="instructorSelect"
                    ></instructor-select>
                </div>
                <program-instructor
                    v-if="currentInstructorSelected"
                    :session="selectedSession"
                    :instructor="currentInstructor"
                    :program="currentProgram"
                >
                </program-instructor>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "program-dashboard",
    components: {
        ProgramSelect: () => import("../components/ProgramSelect"),
        InstructorSelect: () => import("../components/InstructorSelect"),
        ProgramInstructor: () => import("../components/ProgramInstructor"),
    },
    data() {
        return {
            page: {
                title: "Program Dashboard",
                icon: "mdi-view-dashboard-variant",
            },
            selectedSession: null,
        };
    },
    computed: {
        ...mapGetters("Programs", [
            "programs",
            "currentProgram",
            "instructors",
            "currentInstructor",
            "loading",
        ]),
        currentProgramSelected() {
            return Object.keys(this.currentProgram).length > 0;
        },
        currentInstructorSelected() {
            return Object.keys(this.currentInstructor).length > 0;
        },
        programsFilled() {
            return this.programs.length > 0;
        },
        pageBreadcrumbs() {
            return this.currentInstructorSelected
                ? [
                      {
                          text: this.currentInstructor.name,
                          disabled: true,
                          to: "#",
                      },
                  ]
                : [
                      {
                          text: "Program Dashboard",
                          disabled: true,
                          to: "#",
                      },
                  ];
        },
    },
    methods: {
        ...mapActions("Programs", [
            "getCurrentProgram",
            "getPrograms",
            "getCurrentInstructor",
            "getInstructors",
        ]),
        programSelect(program) {
            this.getCurrentProgram(program.id);
        },
        instructorSelect(instructor) {
            this.getCurrentInstructor(instructor.id);
        },
    },
    mounted() {
        this.getPrograms();
    },
};
</script>

<style scoped>
</style>